import { Skeleton } from '@mantine/core';

export function ScreenLoader() {
  return (
    <div className="p-6 flex flex-col gap-2">
      <div className="flex justify-end gap-2 mb-8">
        <Skeleton width={'5%'} height={40} radius={24} />
      </div>
      {/* <Skeleton height={50} circle /> */}
      <Skeleton height={30} radius={24} />
      <Skeleton height={30} radius={24} />
      <Skeleton height={30} radius={24} />
      <Skeleton height={30} radius={24} />
      <Skeleton height={30} radius={24} />
      <Skeleton height={30} radius={24} />
      <Skeleton height={30} radius={24} />
      <div className="flex justify-end gap-2 mt-8">
        <Skeleton width={'10%'} height={40} radius={24} />
        <Skeleton width={'10%'} height={40} radius={24} />
      </div>
    </div>
  );
}
