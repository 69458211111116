import { AppUserModel } from '@/lib/api/client';

export function isAdmin(user: AppUserModel | null): boolean {
  if (!user) return false;
  return user.role === 'admin';
}

export function isAdminOrOwner(user: AppUserModel | null): boolean {
  if (!user) return false;
  return user.role === 'admin' || user.role === 'owner';
}
