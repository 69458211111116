import '@/lib/firebase';
import {
  Avatar,
  Button,
  Divider,
  Loader,
  MantineProvider,
  Menu,
  NavLink,
  rem,
  Tooltip,
  useMantineColorScheme,
} from '@mantine/core';
import '@mantine/core/styles.css';
import { ModalsProvider } from '@mantine/modals';
import { notifications, Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';

import {
  IconCalendarEvent,
  IconHome2,
  IconLogout,
  IconMasksTheaterOff,
  IconMoon,
  IconQuestionMark,
  IconSettingsAutomation,
  IconSun,
  IconUsersGroup,
} from '@tabler/icons-react';
import { getAuth } from 'firebase/auth';
import { lazy, Suspense, useEffect } from 'react';

import { Link, Route, Switch, useLocation } from 'wouter';
import './app.css';
import { AdminOrOwnerOnly } from './components/AdminOnly';
import { LoginComponent } from './components/Login/Login';
import { ScreenLoader } from './components/ScreenLoader';
import { Show } from './components/Show';
import './full-calendar.css';
import { appStore, stopImperonation } from './stores/app.store';
import { theme } from './theme';

const fakeDelay = false;
const fakeDelayTime = 600;

let customLazy = (load: () => Promise<{ default: React.ComponentType<any> }>) => {
  const delayed = () => {
    return new Promise<{ default: React.ComponentType<any> }>(async (res, rej) => {
      setTimeout(async () => {
        res(await load());
      }, fakeDelayTime);
    });
  };
  return lazy(delayed);
};

if (!fakeDelay) {
  customLazy = lazy;
}

const prefetchHome = () => import('./pages/Home.page');
const HomePage = customLazy(() => prefetchHome().then((m) => ({ default: m.HomePage })));
const prefetchReservations = () => import('./pages/Reservations/Reservations.page');
const ReservationsPage = customLazy(() =>
  prefetchReservations().then((m) => ({ default: m.ReservationsPage }))
);

const prefetchSchedule = () => import('./pages/Schedule/Schedule.page');
const SchedulePage = customLazy(() =>
  prefetchSchedule().then((m) => ({ default: m.SchedulePage }))
);

const prefetchProfile = () => import('./pages/Profile/Profile.page');
const ProfilePage = customLazy(() => prefetchProfile().then((m) => ({ default: m.ProfilePage })));

const prefetchUsers = () => import('./pages/Users/Users.page');
const User2Page = customLazy(() => prefetchUsers().then((m) => ({ default: m.UsersPage })));

const prefetchpages = () => import('./pages/SiteManagement');
const SiteManagementPage = customLazy(() =>
  prefetchpages().then((m) => ({ default: m.SiteManagementPage }))
);

const prefetchRanking = () => import('./pages/Ranking.page');
const RankingPage = customLazy(() => prefetchRanking().then((m) => ({ default: m.RankingPage })));

async function prefetchAll() {
  prefetchHome();
  prefetchReservations();
  prefetchSchedule();
  prefetchProfile();
  prefetchUsers();
  prefetchpages();
  prefetchRanking();
}

export default function App() {
  const userLoggedIn = appStore((store) => !!store.user);
  const appLoading = appStore((store) => !!store.appLoading);

  useEffect(() => {
    prefetchAll();
  }, []);

  let body: React.ReactNode;
  if (appLoading) {
    body = (
      <div className="w-screen h-screen flex justify-center items-center">
        <Loader />
      </div>
    );
  } else if (!userLoggedIn) {
    body = <LoginComponent />;
  } else {
    body = (
      <div className="flex min-h-screen max-h-screen max-w-screen overflow-hidden">
        {/* sidebar */}
        <aside className="w-[var(--sidebar-width)]  flex flex-col">
          {/* title */}
          <div className="h-[var(--header-height)] flex flex-col items-center justify-center">
            <Link to="/">
              <p className="shoju">Determinacja</p>
            </Link>
          </div>
          <Divider />
          {/* content */}
          <div className="flex flex-col flex-1 p-1 gap-1">
            <Links />
          </div>
          {/* bottom */}
          <div className="p-1 flex flex-col gap-1">
            <GuideButton />
            <ImpersonateUserButton />
            <Divider />
            <ProfileButton />
          </div>
        </aside>

        <Divider orientation="vertical" />

        <div className="flex-1 flex flex-col">
          <header className="min-h-[var(--header-height)] max-h-[var(--header-height)] p-4 flex justify-between items-center">
            <div className="flex flex-1" id="header-left"></div>
            <div className="flex flex-1" id="header-middle"></div>
            <div className="flex flex-1" id="header-right"></div>
          </header>
          <Divider />
          <main className="flex flex-col max-h-[calc(100vh-var(--header-height))] min-h-[calc(100vh-var(--header-height))] overflow-scroll">
            <Suspense fallback={<ScreenLoader />}>
              <Switch>
                <Route path="/" component={HomePage} />

                <Route path="/schedule" component={SchedulePage}></Route>
                <Route path="/profile" component={ProfilePage}></Route>

                <Route path="/users" component={User2Page}></Route>
                <Route path="/site-management" component={SiteManagementPage}></Route>
                <Route path="/reservations" component={ReservationsPage}></Route>

                <Route path="/ranking" component={RankingPage}></Route>

                <Route>404: No such page!</Route>
              </Switch>
            </Suspense>
          </main>
        </div>
      </div>
    );
  }

  return (
    <MantineProvider theme={theme} defaultColorScheme="auto">
      <ModalsProvider>
        <Notifications />
        {body}
      </ModalsProvider>
    </MantineProvider>
  );
}

function ImpersonateUserButton() {
  const impersonatedUserId = appStore((s) => s.impersonatedUserId);

  async function stopImper() {
    stopImperonation();
    notifications.show({
      title: 'Pomyślnie zakończono impersonację',
      message: '',
      color: 'green',
    });
  }

  return (
    <Show when={!!impersonatedUserId}>
      <Tooltip label="Zakończ impersontację użytkownika">
        <Button
          variant="transparent"
          color="dark.1"
          leftSection={<IconMasksTheaterOff style={{ width: rem(14), height: rem(14) }} />}
          onClick={stopImper}
          className="text-wrap"
        >
          Zakończ impersonację
        </Button>
      </Tooltip>
    </Show>
  );
}

function GuideButton() {
  return (
    <Button variant="transparent" c="dark.1" rightSection={<IconQuestionMark />}>
      Pomoc
    </Button>
  );
}

function ProfileButton() {
  const { toggleColorScheme, colorScheme } = useMantineColorScheme();
  const user = appStore((store) => store.user);

  function logout() {
    getAuth().signOut();
  }

  return (
    <Menu shadow="md" width={190} trigger="hover">
      <Menu.Target>
        <Button
          leftSection={
            <Avatar
              src={user?.profile.avatarUrl}
              style={{ maxWidth: rem(32), maxHeight: rem(32) }}
            />
          }
          className="w-full flex justify-start"
          variant="subtle"
          color="violet"
        >
          {user?.name}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        {/* <Menu.Label>Application</Menu.Label>
        <Menu.Item leftSection={<IconSettings style={{ width: rem(14), height: rem(14) }} />}>
          Settings
        </Menu.Item>
        <Menu.Item leftSection={<IconMessageCircle style={{ width: rem(14), height: rem(14) }} />}>
          Messages
        </Menu.Item>
        <Menu.Item leftSection={<IconPhoto style={{ width: rem(14), height: rem(14) }} />}>
          Gallery
        </Menu.Item>
        <Menu.Item
          leftSection={<IconSearch style={{ width: rem(14), height: rem(14) }} />}
          rightSection={
            <Text size="xs" c="dimmed">
              ⌘K
            </Text>
          }
        >
          Search
        </Menu.Item>

        <Menu.Divider /> */}

        <Menu.Label>Ustawienia</Menu.Label>
        <Menu.Item
          leftSection={
            colorScheme === 'dark' ? (
              <IconSun style={{ width: rem(14), height: rem(14) }} />
            ) : (
              <IconMoon style={{ width: rem(14), height: rem(14) }} />
            )
          }
          onClick={toggleColorScheme}
        >
          Zmień wygląd
        </Menu.Item>
        <Menu.Item
          leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />}
          onClick={logout}
        >
          Wyloguj
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

function Links() {
  const [route, setRouter] = useLocation();

  return (
    <div>
      <NavLink
        to="/"
        component={Link}
        active={route === '/'}
        label="Strona główna"
        leftSection={<IconHome2 size="1rem" stroke={1.5} />}
      ></NavLink>
      <NavLink
        to="/schedule"
        component={Link}
        active={route === '/schedule'}
        label="Grafik"
        leftSection={<IconCalendarEvent size="1rem" stroke={1.5} />}
      ></NavLink>

      <NavLink
        to="/reservations"
        component={Link}
        active={route === '/reservations'}
        label="Rezerwacje"
        leftSection={<IconCalendarEvent size="1rem" stroke={1.5} />}
      ></NavLink>

      <AdminOrOwnerOnly>
        <NavLink
          to="/users"
          component={Link}
          active={route === '/users'}
          label="Użytkownicy"
          leftSection={<IconUsersGroup size="1rem" stroke={1.5} />}
        ></NavLink>
      </AdminOrOwnerOnly>
      <NavLink
        to="/profile"
        component={Link}
        active={route === '/profile'}
        label="Profil"
        leftSection={<IconCalendarEvent size="1rem" stroke={1.5} />}
      ></NavLink>
      {/* <NavLink
        to="/ranking"
        component={Link}
        active={route === '/ranking'}
        label="Tablica wyników"
        leftSection={<IconChartLine size="1rem" stroke={1.5} />}
      ></NavLink> */}
      <AdminOrOwnerOnly>
        <NavLink
          to="/site-management"
          component={Link}
          active={route === '/site-management'}
          label="Zarządzanie stroną"
          leftSection={<IconSettingsAutomation size="1rem" stroke={1.5} />}
        ></NavLink>
      </AdminOrOwnerOnly>
    </div>
  );
}
