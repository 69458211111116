import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { GoogleIcon } from './GoogleIcon';

export function LoginComponent() {
  function loginWithGoogle() {
    const provider = new GoogleAuthProvider();

    provider.setCustomParameters({ prompt: 'select_account' });

    signInWithPopup(getAuth(), provider);
  }

  return (
    <div className="bg-surface-backdrop-token flex h-screen flex-1 flex-col items-center justify-center">
      <div
        className="app-card flex flex-col p-8"
        style={{
          boxShadow: '0px 1px 10px -3px #661e39',
          width: '468px',
          height: '345px',
        }}
      >
        <div className="flex flex-col items-center gap-2">
          <strong className="text-sm shoju">Determinacja</strong>
          <div className="shoju">Dashboard</div>
        </div>
        <div className="flex flex-1 flex-col items-center justify-center">
          <button
            onClick={loginWithGoogle}
            className="btn btn-ghost mx-auto flex w-1/2 items-center gap-4"
          >
            <GoogleIcon className="h-10 w-10" />
            <div>Login with google</div>
          </button>
        </div>
      </div>
    </div>
  );
}
