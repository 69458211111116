import { appConfig } from '@/config';
import { appStore } from '@/stores/app.store';
import { getAuth } from 'firebase/auth';
import createClient, { type Middleware } from 'openapi-fetch';
import type { components, paths } from './v1';

export type AppUserModel = components['schemas']['AppUserModel'];
export type AppUserProfileModel = components['schemas']['AppUserModel'];
export type SearchAppUserRequest = components['schemas']['SearchAppUserRequest'];
export type SaveTimeSlotsEditionRequest = components['schemas']['SaveTimeSlotsEditionRequest'];
export type TimeSlotModel = components['schemas']['TimeSlotModel'];
export type TimeSlotReservationModel = components['schemas']['ReservationModel'];
export type TimeSlotTemplateModel = components['schemas']['TimeSlotTemplateModel'];
export type SettingsModel = components['schemas']['AppSettings'];
export type AppSettingsPrivate = components['schemas']['AppSettingsPrivate'];
export type AppTranslations = components['schemas']['Translation'];
export type AppCounter = components['schemas']['Counter'];
export type GalleryModel = components['schemas']['GalleryModel'];
export type EditedTimeSlotModel = components['schemas']['EditedTimeSlotModel'];
export type DgError = components['schemas']['DgError'];

export const api = createClient<paths>({
  baseUrl: appConfig.backendUrl,
  fetch: async (input: RequestInfo | URL, init?: RequestInit) => {
    const url = getStringFromFetchRequest(input);

    const before = performance.now();
    const res = await fetch(input, init);
    const after = performance.now();

    // await new Promise<void>((res, rej) => {
    //   setTimeout(() => {
    //     res();
    //   }, 1000);
    // });

    console.log(`[CLIENT] [${init?.method ?? 'GET'}] [${url}] took [${after - before}] ms`);

    return res;
  },
});

const authMiddleware: Middleware = {
  async onRequest(req, options) {
    const user = getAuth().currentUser;
    if (!user) {
      return req;
    }

    const token = await user.getIdToken();
    if (!token) {
      return req;
    }

    req.headers.append('Authorization', `Bearer ${token}`);

    const { impersonatedUserId } = appStore.getState();
    if (impersonatedUserId) {
      req.headers.append('Impersonate', impersonatedUserId.toString());
    }

    return req;
  },
};

api.use(authMiddleware);

export function getStringFromFetchRequest(input: RequestInfo | URL): string {
  let url = '';
  if (input instanceof URL) {
    url = input.href;
  } else if (typeof input === 'string') {
    url = input;
  } else if (input instanceof Request) {
    url = input.url;
  }
  return url;
}
