import {
  ActionIcon,
  Button,
  Drawer,
  MantineColorsTuple,
  Modal,
  Tooltip,
  createTheme,
  rem,
} from '@mantine/core';
import styles from './theme.module.css';

// const brand: MantineColorsTuple = [
//   "#faeff3",
//   "#efdce3",
//   "#e1b5c5",
//   "#d48ca6",
//   "#c9698c",
//   "#c2537c",
//   "#c04873",
//   "#aa3a62",
//   "#983257",
//   "#86274b",
// ];

const brand: MantineColorsTuple = [
  '#fff6e0', // 1
  '#ffedcb', // 2
  '#fed89b', // 3
  '#fcc367', // 4
  '#faa41a', // 5 (brand)
  '#faa51d', // 6
  '#fa9f09', // 7
  '#df8b00', // 8
  '#c77a00', // 9
  '#ad6900', // 10
];

// tailwind slate offset 2/3
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const slate: MantineColorsTuple = [
  '#e2e8f0', // 1
  '#cbd5e1', // 2
  '#94a3b8', // 3
  '#64748b', // 4
  '#475569', // 5
  // "#334155", // 6 #111725
  '#111725', // 6 #111725
  '#1e293b',
  '#0f172a',
  '#020617',
  '#020617',
  '#020617',
];

// tailwind gray offset 2/3
const gray: MantineColorsTuple = [
  '#d1d5db',
  '#9ca3af',
  '#6b7280',
  '#4b5563',
  '#374151',
  '#1f2937',
  '#111827',
  '#030712',
  '#030712',
  '#030712',
  '#030712',
];

export const theme = createTheme({
  /** Put your mantine theme override here */
  colors: {
    brand,
    dark: gray,
  },

  primaryColor: 'brand',

  primaryShade: {
    dark: 9,
    light: 7,
  },

  components: {
    Button: Button.extend({
      defaultProps: {
        variant: 'subtle',
      },
    }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        events: { touch: true, focus: true, hover: true },
      },
    }),
    ActionIcon: ActionIcon.extend({
      defaultProps: {
        size: 'lg',
      },
    }),
    Modal: Modal.extend({
      classNames: {
        content: 'modal-content',
      },
    }),
    Drawer: Drawer.extend({
      classNames: {
        content: 'modal-content',
      },
    }),
  },

  activeClassName: styles.active,

  cursorType: 'pointer',

  breakpoints: {
    xs: '30em',
    sm: '48em',
    md: rem(1200),
    lg: rem(1300),
    xl: rem(1500),
  },

  defaultRadius: 'md',
});
