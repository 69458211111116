import { isAdminOrOwner } from '@/lib/userUtils';
import { appStore } from '@/stores/app.store';

export function AdminOrOwnerOnly({ children }: { children: React.ReactNode }) {
  const user = appStore((store) => store.user);

  if (!isAdminOrOwner(user)) return null;

  return children;
}
