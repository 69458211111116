import ReactDOM from 'react-dom/client';
import App from './App';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://3e02dde0c4900641df96722129976138@o4508229454528512.ingest.de.sentry.io/4508229606899792',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: [
  //     'localhost',
  //     /^https:\/\/determinacjagym\.pl\/api/,
  //     /^https:\/\/dev-website\.determinacjagym\.pl\/api/,
  //   ],
  // Session Replay
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.PROD ? 'production' : 'dev',
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && event.event_id) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
